

































import ScreeningSelect from "@/components/hae/ScreeningSelect.vue";
import CommonButton from "@/components/hae/CommonButton.vue";
import QuoteOrigin from "@/components/hae/QuoteOrigin.vue";
import ScreeningHeadline from "@/components/hae/ScreeningHeadline.vue";
import rootStore from "@/store";
import { HaeState } from "@/types/hae";
import { questions } from "../../data/hae";
const state = rootStore.state as { hae: HaeState };
function targetAnswers() {
  return state.hae.screeningAnswers.slice(3, 6);
}
function isContinuable(): boolean {
  return JSON.stringify(targetAnswers()) !== JSON.stringify([0, 0, 0]);
}

export default {
  name: "HaeSecondScreening",
  components: {
    ScreeningSelect,
    CommonButton,
    ScreeningHeadline,
    QuoteOrigin
  },
  head: {
    title: {
      inner: "かんたんHAEセルフチェック"
    }
  },
  data: function() {
    return {
      questions: questions().slice(3, 6)
    };
  },
  computed: {
    isAnswerComplete(): boolean {
      return targetAnswers().every(el => typeof el === "number");
    },
    fetchNextViewName(): string {
      return isContinuable() ? "HaeThirdScreening" : "HaeSecondResult";
    }
  }
};
